<!-- @format -->
<div class="row">
    <div class="col-md-7">
        <loading-layout [loading]="!updateOptions">
            <div class="search-col">
                <h4>
                    <span class="glyphicon glyphicon-search title-icon" aria-hidden="true"></span>
                    &nbsp;Buscar Clasificación por ID
                </h4>
                <label for="clasificacion_id">ID Clasificación</label>
                <input [(ngModel)]="clasificacionId" type="text" class="form-control" />
                <br />

                <button (click)="agregarId()" type="button" class="btn btn-default pull-right">
                    Agregar Clasificación
                </button>
                <h4>
                    <span class="glyphicon glyphicon-search title-icon" aria-hidden="true"></span>
                    &nbsp;Seleccionar Clasificación
                </h4>
                <label for="curriculum">Curriculum</label>
                <select
                    id="curriculum"
                    [(ngModel)]="curriculum"
                    class="form-control"
                    (change)="updateCurriculum($any($event).target.value)"
                >
                    <option value="">-- Seleccione Plataforma --</option>
                    <option value="LGE">Ley General de Educación</option>
                    <option value="LOCE">Ley Organica Constitucional de Enseñanza</option>
                    <option value="PSU">Prueba de Selección Universitaria</option>
                    <option value="PSUM">Prueba de Selección Universitaria (Moraleja)</option>
                    <option value="PAA">PAA (College Board)</option>
                    <option value="SABER">Saber</option>
                    <option value="PDT">Prueba de transición</option>
                    <option value="PAES">Prueba de Acceso a la Educación Superior (PAES)</option>
                    <!-- <option value="SABER11" ngValue="SABER11">Saber 11</option> -->
                    <option value="GRADUATE">Gradúate</option>
                    <option value="CurricularMexico">Curricular México</option>
                    <option value="CurricularColombiaDBA">Curricular Colombia (DBA)</option>
                    <option value="CurricularColombiaEBC">Curricular Colombia (EBC)</option>
                </select>
                <br />
                <form novalidate [formGroup]="form" *ngIf="curriculum && form">
                    <div class="form-group">
                        <label for="asignatura_id">Asignatura</label>
                        <select
                            id="asignatura_id"
                            formControlName="asignatura_id"
                            class="form-control"
                            (change)="updateClasificacion('asignatura_id', 'asignatura')"
                        >
                            <option [ngValue]="0">-- Seleccione Asignatura --</option>
                            <option *ngFor="let option of asignaturas" [ngValue]="option">
                                {{ option.toString() }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group" *ngIf="showhpc">
                        <label for="hpc_id">Habilidades de pensamiento científico</label>
                        <select
                            id="hpc_id"
                            formControlName="hpc_id"
                            class="form-control"
                            (change)="updateClasificacion('hpc_id', 'hpc')"
                        >
                            <option [ngValue]="0">-- Seleccione habilidad de pensamiento cientí­fico --</option>
                            <option *ngFor="let option of hpc" [ngValue]="option">{{ option.toString() }}</option>
                        </select>
                    </div>
                    <div *ngIf="!['PAA', 'PAES'].includes(curriculum)" class="form-group">
                        <label for="curso_id">Curso</label>
                        <select
                            id="curso_id"
                            formControlName="curso_id"
                            class="form-control"
                            (change)="updateClasificacion('curso_id', 'curso')"
                        >
                            <option [ngValue]="0">-- Seleccione Curso --</option>
                            <option *ngFor="let option of cursos" [ngValue]="option">{{ option.toString() }}</option>
                        </select>
                    </div>
                    <div *ngIf="curriculum == 'PDT'" class="form-group">
                        <label for="nivel1_id">Nivel 1</label>
                        <select
                            id="nivel1_id"
                            formControlName="nivel1_id"
                            class="form-control"
                            (change)="updateClasificacion('nivel1_id', 'nivel1')"
                        >
                            <option [ngValue]="0">-- Seleccione Nivel 1 --</option>
                            <option *ngFor="let option of nivel1" [ngValue]="option">{{ option.toString() }}</option>
                        </select>
                    </div>
                    <div *ngIf="curriculum == 'PDT'" class="form-group">
                        <label for="nivel2_id">Nivel 2</label>
                        <select
                            id="nivel2_id"
                            formControlName="nivel2_id"
                            class="form-control"
                            (change)="updateClasificacion('nivel2_id', 'nivel2')"
                        >
                            <option [ngValue]="0">-- Seleccione Nivel 2 --</option>
                            <option *ngFor="let option of nivel2" [ngValue]="option">{{ option.toString() }}</option>
                        </select>
                    </div>
                    <div *ngIf="curriculum == 'PDT'" class="form-group">
                        <label for="nivel3_id">Nivel 3</label>
                        <select
                            id="nivel3_id"
                            formControlName="nivel3_id"
                            class="form-control"
                            (change)="updateClasificacion('nivel3_id', 'nivel3')"
                        >
                            <option [ngValue]="0">-- Seleccione Nivel 3 --</option>
                            <option *ngFor="let option of nivel3" [ngValue]="option">{{ option.toString() }}</option>
                        </select>
                    </div>
                    <div *ngIf="recursoEducativo == 'pregunta' && !['PAES'].includes(curriculum)" class="form-group">
                        <label for="dificultad_id">Dificultad</label>
                        <select
                            id="dificultad_id"
                            formControlName="dificultad_id"
                            class="form-control"
                            (change)="updateClasificacion('dificultad_id', 'dificultad')"
                        >
                            <option [ngValue]="0">-- Seleccione Dificultad --</option>
                            <option *ngFor="let option of dificultades" [ngValue]="option">
                                {{ option.toString() }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group" *ngIf="recursoEducativo == 'material'">
                        <label for="dirigido_id">Dirigido para</label>
                        <select
                            id="dirigido_id"
                            formControlName="dirigido_id"
                            class="form-control"
                            (change)="updateClasificacion('dirigido_id', 'dirigido')"
                        >
                            <option [ngValue]="0">-- Seleccione Dirigido para --</option>
                            <option *ngFor="let option of dirigidos" [ngValue]="option">{{ option.toString() }}</option>
                        </select>
                    </div>
                    <div *ngIf="curriculum == 'LOCE'" class="form-group">
                        <label for="eje_hab_unidad_id">Eje/Habilidad/Unidad</label>
                        <select
                            id="eje_hab_unidad_id"
                            formControlName="eje_hab_unidad_id"
                            class="form-control"
                            (change)="updateClasificacion('eje_hab_unidad_id', 'mezclados')"
                        >
                            <option [ngValue]="0">-- Seleccione Eje/Habilidad/Unidad --</option>
                            <option *ngFor="let option of mezclados" [ngValue]="option">{{ option.toString() }}</option>
                        </select>
                    </div>
                    <div
                        *ngIf="
                            curriculum == 'PSU' ||
                            curriculum == 'SABER11' ||
                            curriculum == 'GRADUATE' ||
                            curriculum == 'PAA' ||
                            curriculum == 'LGE' ||
                            curriculum == 'PSUM' ||
                            curriculum == 'CurricularColombiaDBA' ||
                            curriculum == 'CurricularColombiaEBC' ||
                            curriculum == 'PAES'
                        "
                        class="form-group"
                    >
                        <label for="eje_id">Eje</label>
                        <select
                            id="eje_id"
                            formControlName="eje_id"
                            class="form-control"
                            (change)="updateClasificacion('eje_id', 'eje')"
                        >
                            <option [ngValue]="0">-- Seleccione Eje --</option>
                            <option *ngFor="let option of ejes" [ngValue]="option">
                                {{ option.toString() }}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="curriculum == 'PAES'" class="form-group">
                        <label for="unidad_tematica_id">Unidad Temática</label>
                        <select
                            id="unidad_tematica_id"
                            formControlName="unidad_tematica_id"
                            class="form-control"
                            (change)="updateClasificacion('unidad_tematica_id', 'unidad_tematica')"
                        >
                            <option [ngValue]="0">-- Seleccione Unidad Temática --</option>
                            <option *ngFor="let option of unidades_tematicas" [ngValue]="option">
                                {{ option.toString() }}
                            </option>
                        </select>
                    </div>
                    <div
                        *ngIf="
                            curriculum == 'PSU' ||
                            curriculum == 'SABER11' ||
                            curriculum == 'GRADUATE' ||
                            curriculum == 'PAA' ||
                            curriculum == 'CurricularColombiaEBC'
                        "
                        class="form-group"
                    >
                        <label for="sub_eje_id">Sub-Eje</label>
                        <select
                            id="sub_eje_id"
                            formControlName="sub_eje_id"
                            class="form-control"
                            (change)="updateClasificacion('sub_eje_id', 'sub_eje')"
                        >
                            <option [ngValue]="0">-- Seleccione Sub-Eje --</option>
                            <option *ngFor="let option of sub_ejes" [ngValue]="option">
                                {{ option.toString() }}
                            </option>
                        </select>
                    </div>
                    <div
                        *ngIf="form.get('asignatura_id').value.asignatura == 'Lenguaje' && curriculum == 'PSU'"
                        class="form-group"
                    >
                        <ng-container *ngTemplateOutlet="typeTextSelect; context: typeText"></ng-container>
                    </div>

                    <div
                        *ngIf="form.get('asignatura_id').value.asignatura == 'Lenguaje' && curriculum == 'PSU'"
                        class="form-group"
                    >
                        <ng-container *ngTemplateOutlet="genreSelect; context: genreOptions"></ng-container>
                    </div>

                    <div *ngIf="curriculum == 'PSU'" class="form-group">
                        <ng-container *ngTemplateOutlet="cmoSelect; context: cmoOptions"></ng-container>
                    </div>
                    <div *ngIf="curriculum == 'PSUM' || curriculum == 'PAES'" class="form-group">
                        <label for="tema_id">Tema</label>
                        <select
                            id="tema_id"
                            formControlName="tema_id"
                            class="form-control"
                            (change)="updateClasificacion('tema_id', 'tema')"
                        >
                            <option [ngValue]="0">-- Seleccione Tema --</option>
                            <option *ngFor="let option of temas" [ngValue]="option">{{ option.toString() }}</option>
                        </select>
                    </div>
                    <div *ngIf="curriculum == 'PAES'" class="form-group">
                        <label for="conocimiento_id">Conocimiento</label>
                        <select
                            id="conocimiento_id"
                            formControlName="conocimiento_id"
                            class="form-control"
                            (change)="updateClasificacion('conocimiento_id', 'conocimiento')"
                        >
                            <option [ngValue]="0">-- Seleccione Conocimiento --</option>
                            <option *ngFor="let option of conocimientos" [ngValue]="option">
                                {{ option.toString() }}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="curriculum == 'PSUM'" class="form-group">
                        <label for="sub_tema_id">Sub Tema</label>
                        <select
                            id="sub_tema_id"
                            formControlName="sub_tema_id"
                            class="form-control"
                            (change)="updateClasificacion('sub_tema_id', 'sub_tema')"
                        >
                            <option [ngValue]="0">-- Seleccione Sub Tema --</option>
                            <option *ngFor="let option of sub_temas" [ngValue]="option">{{ option.toString() }}</option>
                        </select>
                    </div>
                    <div *ngIf="curriculum == 'LGE'" class="form-group">
                        <label for="uni_id">Unidad</label>
                        <select
                            id="uni_id"
                            formControlName="uni_id"
                            class="form-control"
                            (change)="updateClasificacion('uni_id', 'unidad')"
                        >
                            <option [ngValue]="0">-- Seleccione Unidad --</option>
                            <option *ngFor="let option of unidades" [ngValue]="option">{{ option.toString() }}</option>
                        </select>
                    </div>
                    <div *ngIf="curriculum == 'LGE' || curriculum == 'CurricularColombiaEBC'" class="form-group">
                        <label for="oa_id">Objetivo de Aprendizaje</label>
                        <select
                            id="oa_id"
                            formControlName="oa_id"
                            class="form-control"
                            (change)="updateClasificacion('oa_id', 'oa')"
                        >
                            <option [ngValue]="0">-- Todos --</option>
                            <option *ngFor="let option of oas" [ngValue]="option">{{ option.toString() }}</option>
                        </select>
                    </div>
                    <div
                        *ngIf="form.get('asignatura_id').value.asignatura == 'Lenguaje' && curriculum == 'LGE'"
                        class="form-group"
                    >
                        <ng-container *ngTemplateOutlet="typeTextSelect; context: typeText"></ng-container>
                    </div>
                    <div
                        *ngIf="form.get('asignatura_id').value.asignatura == 'Lenguaje' && curriculum == 'LGE'"
                        class="form-group"
                    >
                        <ng-container *ngTemplateOutlet="genreSelect; context: genreOptions"></ng-container>
                    </div>
                    <div
                        *ngIf="
                            curriculum == 'LGE' ||
                            curriculum == 'LOCE' ||
                            curriculum == 'PAA' ||
                            curriculum == 'CurricularColombiaEBC'
                        "
                        class="form-group"
                    >
                        <label for="indicador_id">Indicadores</label>
                        <select
                            id="indicador_id"
                            formControlName="indicador_id"
                            class="form-control"
                            (change)="updateClasificacion('indicador_id', 'indicador')"
                        >
                            <option [ngValue]="0">-- Todos --</option>
                            <option *ngFor="let option of indicadores" [ngValue]="option">
                                {{ option.toString() }}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="curriculum == 'PAA'" class="form-group">
                        <label for="sub_indicador_id">Sub-Indicadores</label>
                        <select id="sub_indicador_id" formControlName="sub_indicador_id" class="form-control">
                            <option [ngValue]="0">-- Todos --</option>
                            <option *ngFor="let option of sub_indicadores" [ngValue]="option">
                                {{ option.toString() }}
                            </option>
                        </select>
                    </div>
                    <div
                        *ngIf="
                            curriculum == 'PSU' ||
                            curriculum == 'PSUM' ||
                            curriculum == 'SABER11' ||
                            curriculum == 'PAA' ||
                            curriculum == 'LGE' ||
                            curriculum == 'PDT' ||
                            curriculum == 'PAES'
                        "
                        class="form-group"
                    >
                        <label for="hab_id">Habilidad</label>
                        <select
                            id="hab_id"
                            formControlName="hab_id"
                            class="form-control"
                            (change)="!islge || updateClasificacion('hab_id', 'habilidad')"
                        >
                            <option [ngValue]="0">-- Seleccione Habilidad --</option>
                            <option *ngFor="let option of habilidades" [ngValue]="option">
                                {{ option.toString() }}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="curriculum == 'PDT'" class="form-group">
                        <label for="sub_hab_id">Sub Habilidad</label>
                        <select
                            id="sub_hab_id"
                            formControlName="sub_hab_id"
                            class="form-control"
                            (change)="!islge || updateClasificacion('sub_hab_id', 'sub_habilidad')"
                        >
                            <option [ngValue]="0">-- Seleccione subHabilidad --</option>
                            <option *ngFor="let option of sub_habilidades" [ngValue]="option">
                                {{ option.toString() }}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="curriculum == 'LGE'" class="form-group">
                        <label for="oah_id">Objetivo Aprendizaje Habilidad</label>
                        <select
                            id="oah_id"
                            formControlName="oah_id"
                            class="form-control"
                            (change)="updateClasificacion('oah_id', 'oah')"
                        >
                            <option [ngValue]="0">-- Seleccione Objetivo Aprendizaje Habilidad--</option>
                            <option *ngFor="let option of oahs" [ngValue]="option">{{ option.toString() }}</option>
                        </select>
                    </div>
                    <div *ngIf="curriculum == 'LOCE'" class="form-group">
                        <label for="ae_id">Aprendizaje Esperado</label>
                        <select
                            id="ae_id"
                            formControlName="ae_id"
                            class="form-control"
                            (change)="updateClasificacion('ae_id', 'ae')"
                        >
                            <option [ngValue]="0">-- Todos --</option>
                            <option *ngFor="let option of aes" [ngValue]="option">{{ option.toString() }}</option>
                        </select>
                    </div>
                    <div *ngIf="curriculum == 'SABER'" class="form-group">
                        <label for="componente_id">Componente</label>
                        <select
                            id="componente_id"
                            formControlName="componente_id"
                            class="form-control"
                            (change)="updateClasificacion('componente_id', 'componente')"
                        >
                            <option [ngValue]="0">-- Seleccione Componente --</option>
                            <option *ngFor="let option of componentes" [ngValue]="option">
                                {{ option.toString() }}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="curriculum == 'SABER'" class="form-group">
                        <label for="competencia_id">Competencia</label>
                        <select
                            id="competencia_id"
                            formControlName="competencia_id"
                            class="form-control"
                            (change)="updateClasificacion('competencia_id', 'competencia')"
                        >
                            <option [ngValue]="0">-- Competencia --</option>
                            <option *ngFor="let option of competencias" [ngValue]="option">
                                {{ option.toString() }}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="curriculum == 'SABER'" class="form-group">
                        <label for="afirmacion_id">Afirmación</label>
                        <select
                            id="afirmacion_id"
                            formControlName="afirmacion_id"
                            class="form-control"
                            (change)="updateClasificacion('afirmacion_id', 'afirmacion')"
                        >
                            <option [ngValue]="0">-- Afirmación --</option>
                            <option *ngFor="let option of afirmaciones" [ngValue]="option">
                                {{ option.toString() }}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="curriculum == 'SABER'" class="form-group">
                        <label for="evidencia_id">Evidencia</label>
                        <select id="evidencia_id" formControlName="evidencia_id" class="form-control">
                            <option [ngValue]="0">-- Evidencia --</option>
                            <option *ngFor="let option of evidencias" [ngValue]="option">
                                {{ option.toString() }}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="curriculum == 'LGE' || curriculum == 'LOCE'" class="form-group">
                        <div class="checkbox">
                            <label>
                                <input id="pie_id" formControlName="pie_id" type="checkbox" />
                                Programa de Integración Escolar (PIE)
                            </label>
                        </div>
                    </div>

                    <div *ngIf="material && (curriculum == 'LGE' || curriculum == 'LOCE')" class="form-group">
                        <div class="checkbox">
                            <label>
                                <input id="docente_id" formControlName="docente_id" type="checkbox" />
                                Para Docente
                            </label>
                        </div>
                    </div>

                    <div *ngIf="curriculum == 'CurricularMexico' && showBloqueMx" class="form-group">
                        <label for="bloque_mx_id">Bloque mx</label>
                        <select
                            id="bloque_mx_id"
                            formControlName="bloque_mx_id"
                            class="form-control"
                            (change)="updateClasificacion('bloque_mx_id', 'bloques_mx')"
                        >
                            <option [ngValue]="0">-- Bloque mx --</option>
                            <option *ngFor="let option of bloques_mx" [ngValue]="option">
                                {{ option.toString() }}
                            </option>
                        </select>
                    </div>

                    <div *ngIf="curriculum == 'CurricularMexico' && showBloqueMx" class="form-group">
                        <label for="tema_mx_id">Tema mx</label>
                        <select
                            id="tema_mx_id"
                            formControlName="tema_mx_id"
                            class="form-control"
                            (change)="updateClasificacion('tema_mx_id', 'temas_mx')"
                        >
                            <option [ngValue]="0">-- Tema mx --</option>
                            <option *ngFor="let option of temas_mx" [ngValue]="option">
                                {{ option.toString() }}
                            </option>
                        </select>
                    </div>

                    <div *ngIf="curriculum == 'CurricularMexico' && showBloqueMx" class="form-group">
                        <label for="subtema_mx_id">Sub Tema mx</label>
                        <select id="subtema_mx_id" formControlName="subtema_mx_id" class="form-control">
                            <option [ngValue]="0">-- Sub Tema mx --</option>
                            <option *ngFor="let option of subtemas_mx" [ngValue]="option">
                                {{ option.toString() }}
                            </option>
                        </select>
                    </div>

                    <!-- <div *ngIf="curriculum == 'CurricularMexico' && showComponenteMx" class="form-group">
                        <label for="componente_mx_id">Componente mx</label>
                        <select
                            id="componente_mx_id"
                            formControlName="componente_mx_id"
                            class="form-control"
                            (change)="updateClasificacion('componente_mx_id', 'componentes_mx')"
                        >
                            <option [ngValue]="0">-- Componente mx --</option>
                            <option *ngFor="let option of componentes_mx" [ngValue]="option">
                                {{ option.toString() }}
                            </option>
                        </select>
                    </div>

                    <div *ngIf="curriculum == 'CurricularMexico' && showComponenteMx" class="form-group">
                        <label for="contenidocentral_mx_id">Contenido central mx</label>
                        <select
                            id="contenidocentral_mx_id"
                            formControlName="contenidocentral_mx_id"
                            class="form-control"
                        >
                            <option [ngValue]="0">-- Contenido central mx --</option>
                            <option *ngFor="let option of contenidocentrales_mx" [ngValue]="option">
                                {{ option.toString() }}
                            </option>
                        </select>
                    </div> -->

                    <div *ngIf="curriculum == 'CurricularColombiaDBA'" class="form-group">
                        <label for="categoria_organizadora_CO_id">Categoria Organizadora CO</label>
                        <select
                            id="categoria_organizadora_CO_id"
                            formControlName="categoria_organizadora_CO_id"
                            class="form-control"
                        >
                            <option [ngValue]="0">-- Categoria Organizadora CO --</option>
                            <option *ngFor="let option of categoria_organizadoras_CO" [ngValue]="option">
                                {{ option.toString() }}
                            </option>
                        </select>
                    </div>

                    <div *ngIf="curriculum == 'CurricularColombiaDBA'" class="form-group">
                        <label for="derechos_basicos_aprendizaje_CO_id">Derechos Basicos de aprendizaje CO</label>
                        <select
                            id="derechos_basicos_aprendizaje_CO_id"
                            formControlName="derechos_basicos_aprendizaje_CO_id"
                            class="form-control"
                        >
                            <option [ngValue]="0">-- Derechos Basicos de aprendizaje CO --</option>
                            <option *ngFor="let option of derechos_basicos_aprendizaje_CO" [ngValue]="option">
                                {{ option.toString() }}
                            </option>
                        </select>
                    </div>

                    <div *ngIf="curriculum == 'CurricularColombiaDBA'" class="form-group">
                        <label for="evidencia_aprendizaje_CO_id">Evidencia aprendizaje CO</label>
                        <select
                            id="evidencia_aprendizaje_CO_id"
                            formControlName="evidencia_aprendizaje_CO_id"
                            class="form-control"
                        >
                            <option [ngValue]="0">-- Evidencia aprendizaje CO --</option>
                            <option *ngFor="let option of evidencia_aprendizajes_CO" [ngValue]="option">
                                {{ option.toString() }}
                            </option>
                        </select>
                    </div>

                    <div *ngIf="material" class="form-group">
                        <label>Apto para plan personal</label>
                        <div class="checkbox-wrap">
                            <div class="checkbox" *ngFor="let option of aptoParaPlanPersonal; let i = index">
                                <label formArrayName="apto_para_plan_personal_id">
                                    <input type="checkbox" [formControlName]="i" [value]="option.id" />
                                    {{ option.toString() }}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="clearfix">
                        <button (click)="agregar()" type="button" class="btn btn-default pull-right">
                            Agregar Clasificación
                        </button>
                    </div>

                    <div
                        *ngIf="curriculum === 'PAES' && form.get('asignatura_id').value.asignatura === 'Lenguaje'"
                        class="form-group"
                    >
                        <label for="subhabilidad">Subhabilidad</label>
                        <select
                            id="subhabilidad"
                            formControlName="subhabilidad"
                            class="form-control"
                            (change)="updateClasificacion('subhabilidad', 'subhabilidad')"
                        >
                            <option [ngValue]="0">-- Seleccione --</option>
                            <option *ngFor="let option of sub_habilidades_paes" [ngValue]="option">
                                {{ option.toString() }}
                            </option>
                        </select>
                    </div>

                    <div
                        *ngIf="curriculum === 'PAES' && form.get('asignatura_id').value.asignatura === 'Lenguaje'"
                        class="form-group"
                    >
                        <label for="tareas_lectoras">Tareas lectoras</label>
                        <select id="tareas_lectoras" formControlName="tareas_lectoras" class="form-control">
                            <option [ngValue]="0">-- Seleccione --</option>
                            <option *ngFor="let option of tareas_lectoras" [ngValue]="option">
                                {{ option.toString() }}
                            </option>
                        </select>
                    </div>

                    <ng-container *ngIf="['PSU', 'PSUM', 'PDT', 'PAES'].includes(curriculum)">
                        <h4>Clasificaciones adicionales</h4>
                        <div class="form-group">
                            <label>Suficiencia</label>
                            <select
                                id="suficiencia"
                                formControlName="suficiencia"
                                class="form-control"
                                [(ngModel)]="suficienciaSelected"
                            >
                                <option [ngValue]="undefined" selected>-- Seleccione Suficiencia --</option>
                                <option *ngFor="let option of suficiencia" [ngValue]="option">
                                    {{ option.toString() }}
                                </option>
                            </select>
                        </div>
                        <div class="clearfix">
                            <button (click)="agregarSuficiencia()" type="button" class="btn btn-default pull-right">
                                Agregar suficiencia
                            </button>
                        </div>
                        <div class="form-group">
                            <label>Mención</label>
                            <select
                                id="mencion"
                                formControlName="mencion"
                                class="form-control"
                                [(ngModel)]="mencionSelected"
                            >
                                <option [ngValue]="undefined" selected>-- Seleccione Mención --</option>
                                <option *ngFor="let option of mencion" [ngValue]="option">
                                    {{ option.toString() }}
                                </option>
                            </select>
                        </div>
                        <div class="clearfix">
                            <button (click)="agregarMencion()" type="button" class="btn btn-default pull-right">
                                Agregar mención
                            </button>
                        </div>
                    </ng-container>
                    <!--Templates Selects that may be repeated-->
                    <ng-template #typeTextSelect>
                        <label for="type_text_id">Tipo Texto</label>
                        <select
                            id="type_text_id"
                            formControlName="type_text_id"
                            class="form-control"
                            (change)="updateClasificacion('type_text_id', 'type_text')"
                        >
                            <option [ngValue]="0">-- Tipo Texto --</option>
                            <option *ngFor="let clasification of typeText" [ngValue]="clasification">
                                {{ clasification.toString() }}
                            </option>
                        </select>
                    </ng-template>
                    <ng-template #genreSelect>
                        <label for="genre_id">Género</label>
                        <select
                            id="genre_id"
                            formControlName="genre_id"
                            class="form-control"
                            (change)="updateClasificacion('genre_id', 'genre')"
                        >
                            <option [ngValue]="0">-- Género --</option>
                            <option *ngFor="let clasification of genreOptions" [ngValue]="clasification">
                                {{ clasification.toString() }}
                            </option>
                        </select>
                    </ng-template>
                    <ng-template #cmoSelect>
                        <label for="cmo_id">Contenido mínimo obligatorio</label>
                        <select
                            id="cmo_id"
                            formControlName="cmo_id"
                            class="form-control"
                            (change)="updateClasificacion('cmo', 'of')"
                        >
                            <option [ngValue]="0">-- Contenido mínimo obligatorio --</option>
                            <option *ngFor="let clasification of cmoOptions" [ngValue]="clasification">
                                {{ clasification.toString() }}
                            </option>
                        </select>
                    </ng-template>
                    <!--FIN Templates Selects that may be repeated-->
                </form>
            </div>
        </loading-layout>
    </div>

    <div class="col-md-5">
        <h4>
            <span class="glyphicon glyphicon-list title-icon" aria-hidden="true"></span>
            &nbsp;Clasificaciones Seleccionadas
        </h4>
        <div *ngIf="clasificaciones_seleccionadas.length == 0" class="empty-list">
            No hay clasificaciones seleccionadas.
        </div>
        <ng-container *ngFor="let clasificacionTipo of clasificacionesByClasificacionTipo | keys">
            <h4>{{ clasificacionTipo }}</h4>
            <ul class="lista-clasificaciones">
                <li *ngFor="let clasificacion of clasificacionesByClasificacionTipo[clasificacionTipo]">
                    <p>
                        <b>{{ clasificacion.id }} - {{ clasificacion.clasificacion }}</b>
                        <span [innerHTML]="clasificacion.descripcion | trustedhtml"></span>
                    </p>
                    <button (click)="quitar(clasificacion.id)" type="button" class="btn btn-default btn-sm">
                        <span class="glyphicon glyphicon-remove"></span>
                    </button>
                </li>
            </ul>
        </ng-container>
    </div>
</div>
